import React from "react";
import { graphql } from "gatsby";
import { ContentSection, FormModalCTA, PageTemplateWrapper } from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import "../styles/main.less";

const pageData = {
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    section_1_title: "为企业、教育机构和培训中心提供全面的外语培训解决方案",
    section_1_body:
        "您的员工和学生可在任何时间和地点通过Rosetta Stone 云计算外语学习平台开展专属的个性化外语学习。我们的移动应用全面针对当代学生的信息获取习惯，即使是繁忙的企业员工也能轻松在碎片时间里感受外语学习的魅力。电脑、平板或智能手机，都是您的连线学习的好伙伴。",
    section_1_cta_caption: "",
    section_1_secondary_cta: "",
    verticals_title: "我们的解决方案包括",
    training_title: "培训中心",
    training_description: "针对各类外语培训中心提供个性化的混合式培训解决方案",
    training_url: "/training-centers/",
    education_title: "教育机构",
    education_description: "针对基础或高等教育机构提供的完整的外语教育解决方案",
    education_url: "/education/",
    business_title: "企业",
    business_description: "为企业初级至高级水平的员工提供实用的外语培训解决方案",
    business_url: "/business/"
};

const Page = ({ data }) => {
    const { site } = data;
    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "consumer",
        locale: site.siteMetadata.lang,
        page: "cn-consumer-home"
    });

    return (
        <PageTemplateWrapper config={config}>
            <ContentSection
                dataQa="1"
                sectionClasses="section__left section_1"
                img={data.section_1_img_1}
                title={pageData.section_1_title}
                mainTitle
                body={pageData.section_1_body}
                cta_caption={pageData.section_1_cta_caption}
                resourcesLink={data.copy.data.resources_link}
                vertical={data.site.siteMetadata.vertical}
                hasCombinedResources={data.copy.data.has_combined_resources}
                market={data.copy.data.market}
                content_child={
                    <FormModalCTA
                        dataQa="masthead-request-pricing-button"
                        btn_classes="btn btn-blue btn-sm"
                        btn_title={data.page.data.section_1_secondary_cta}
                        pricing_title={data.copy.data.pricing_title}
                        pricing_subtitle={data.copy.data.pricing_subtitle}
                        domain={data.site.siteMetadata.legacy.domain}
                        vertical={data.site.siteMetadata.vertical}
                        copyData={data.copy.data}
                    />
                }
            />
        </PageTemplateWrapper>
    );
};

export default Page;

export const pageQuery = graphql`
    query {
        copy: airtable(data: { title: { eq: "cn-copy" } }) {
            ...PagesCopyFragment
        }
        items: allAirtable(
            filter: {
                table: { eq: "Items" }
                id: {
                    in: [
                        "Airtable_recKH4erDob2cWMnU"
                        "Airtable_rec4Va9abOvfG5AFb"
                        "Airtable_recJe5WnAvXaEh3wr"
                        "Airtable_rec7EKOIAY17IPogQ"
                    ]
                }
            }
        ) {
            edges {
                node {
                    ...ItemsFragment
                }
            }
        }
        page: airtable(data: { title: { eq: "cn-corp" } }) {
            ...PagesHomeFragment
        }
        site {
            ...SiteFragment
        }
        section_1_img_1: file(relativePath: { eq: "images/three-women-phone.png" }) {
            childImageSharp {
                fixed(width: 1034, height: 586) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
                fluid(maxWidth: 1034, quality: 80) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        k12_circle: file(relativePath: { eq: "images/k12-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        hed_circle: file(relativePath: { eq: "images/hed-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        business_circle: file(relativePath: { eq: "images/business-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        pub_circle: file(relativePath: { eq: "images/pub-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`;
